/* Tables */

.table {
  margin-bottom: 0;
  color: $text-muted;
  

  thead {
    th {
      border-top: 0;
      border-bottom-width: 1px;
      font-weight: $font-weight-medium;
      color: $text-muted; 
      

      i {
        margin-left: 0.325rem;
      }
    }
  }

  th,
  td {
    vertical-align: middle;
    font-size: $default-font-size;
    line-height: 1;
    white-space: nowrap;
    padding: $table-cell-padding;

    img {
      width: 30px;
      height: 30px;
      border-radius: 100%;
    }

    .badge {
      margin-bottom: 0;
    }
  }

  &.table-borderless {
    border: none;

    tr,
    td,
    th {
      border: none;
    }
  }
  .table-dark {
    background: $table-dark-bg;
  }
}
.table > :not(:last-child) > :last-child > *, .jsgrid .jsgrid-table > :not(:last-child) > :last-child > * {
  border-bottom-color: $border-color;
}
.table > :not(:first-child), .jsgrid .jsgrid-table > :not(:first-child) {
  border-top: none;
}
.table-hover {
  tr:hover, td {
    color: $white;
  }
}

.table-striped tr:nth-child(odd) td{
  color: $gray-light;
}